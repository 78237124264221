
import { Vue, Options } from 'vue-class-component';
import {
  BaseTextInput,
  BaseButton,
  BaseIcon,
  BasePopover,
  PermissionGroup,
  BaseSwitch,
  PopoverButton,
  PopoverLink,
  BaseCard,
  CardHeader
} from '@/lib/components';
import { IPermission } from '@/lib';
import { OrganisationPermissionService, OrganisationRoleService } from '@/services/api';
import { Permission, Role } from '@/models';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    roleId: {
      type: String,
      required: true
    }
  },
  components: {
    CardHeader,
    BaseCard,
    PopoverLink,
    PopoverButton,
    BaseSwitch,
    PermissionGroup,
    BaseTextInput,
    BaseButton,
    BasePopover,
    BaseIcon
  }
})
export default class RolePage extends Vue {
  roleId!: number;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  saving = false;
  name = '';
  roleName = '';
  errors: { [key: string]: Array<string> } = {};
  permissions: { [key: string]: Array<IPermission> } = {};
  request: CancelTokenSource | null = null;

  get orgRoleService(): OrganisationRoleService {
    return new OrganisationRoleService(this.organisationId);
  }

  get permissionService(): OrganisationPermissionService {
    return new OrganisationPermissionService(this.organisationId);
  }

  get canAssignPermission(): boolean {
    if (this.$route.meta?.isAdmin) {
      return this.sessionStore.currentUser?.is_admin;
    }
    return this.sessionStore.permissions.find((permission: string) => permission === 'role:assign-permission');
  }

  get organisationId() {
    return this.$route.params.organisationId || this.sessionStore.currentOrganisationId;
  }

  get checkedPermissionsKeys() {
    return this.selectedPermissions.filter((p) => p.isChecked).map((p) => p.key);
  }

  get selectedPermissions() {
    return Object.values(this.permissions).flat();
  }

  get switchValue() {
    return this.checkedPermissionsKeys.length === this.selectedPermissions.length;
  }

  created() {
    this.progressStore.startProgress();
    this.fetchData();
    if (!this.$route.meta?.isAdmin) {
      this.$watch('organisationId', () => {
        this.$router.push({ name: 'settings-roles' });
      });
    }
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchData() {
    try {
      this.request = axios.CancelToken.source();
      const role = await this.fetchRole(this.request.token);
      this.name = role.name;
      this.roleName = role.name;
      const permissions = await this.fetchPermissions(this.request.token);
      this.request = null;
      // Permissions with `isChecked` to check if User has permission enabled
      const mapToIPermission = (permission: Permission): IPermission => ({
        ...permission,
        isChecked: !!role.permissions.find((p) => p.key === permission.key)
      });

      const permissionWithValue = permissions.map(mapToIPermission);

      // Group the permission list by its category
      this.permissions = permissionWithValue.reduce((list, permission) => {
        const category = permission.category;
        (list[category] ? list[category] : (list[category] = [])).push(permission);
        return list;
      }, {} as { [category: string]: Array<IPermission> });
      this.progressStore.finishProgress();
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.role.fetch-one-error')
        });
      }
    }
  }

  async fetchRole(cancelToken: CancelToken): Promise<Role> {
    return await this.orgRoleService.fetch(this.roleId, {
      cancelToken
    });
  }

  async fetchPermissions(cancelToken: CancelToken) {
    return (
      await this.permissionService.index({
        cancelToken
      })
    ).data;
  }

  onPermissionsChange(groupIndex: string, permissions: Array<IPermission>) {
    this.permissions = {
      ...this.permissions,
      [groupIndex]: permissions
    };
  }

  goBack() {
    this.$router.back();
  }

  async deleteRole() {
    try {
      await this.orgRoleService.delete(this.roleId);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.role.deactivate-success')
      });
      this.goBack();
    } catch (err) {
      console.warn(err);
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.deactivate-error')
      });
    }
  }

  checkAll(value: boolean) {
    const newPermissions = JSON.parse(JSON.stringify(this.permissions));
    for (let i = 0; i < Object.keys(newPermissions).length; i++) {
      for (let j = 0; j < newPermissions[Object.keys(this.permissions)[i]].length; j++) {
        const key = Object.keys(newPermissions)[i];
        newPermissions[key][j].isChecked = value;
      }
    }
    this.permissions = { ...newPermissions };
  }

  async saveRole() {
    this.saving = true;
    try {
      if (this.name !== this.roleName) {
        await this.orgRoleService.update(this.roleId, {
          name: this.name
        });
      }
      if (this.canAssignPermission) {
        await this.orgRoleService.setPermissions(this.roleId, this.checkedPermissionsKeys);
      }
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.role.update-success')
      });
      this.$router.go(0);
    } catch (error) {
      this.errors = error.response.data.errors;
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.update-error')
      });
    } finally {
      window.scrollTo(0, 0); // Scroll to top so as to view the notification
      this.saving = false;
    }
  }
}
