import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "flex flex-row justify-between" }
const _hoisted_4 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_5 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_CardHeader = _resolveComponent("CardHeader")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_PermissionGroup = _resolveComponent("PermissionGroup")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseCard, { class: "space-y-10" }, {
      default: _withCtx(() => [
        _createVNode(_component_CardHeader, {
          title: _ctx.$t('platform.role.edit'),
          onBack: _ctx.goBack
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BasePopover, {
              position: "left",
              class: "ml-4"
            }, {
              button: _withCtx(() => [
                _createVNode(_component_BaseButton, {
                  color: "ghost",
                  "left-icon": "pin"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.options')), 1)
                  ]),
                  _: 1
                })
              ]),
              menu: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_PopoverLink, {
                  to: _ctx.roleId + '/users'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.user.view')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])), [
                  [_directive_allow, 'user:read']
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteRole()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.role.deactivate')), 1)
                  ]),
                  _: 1
                })), [
                  [_directive_allow, 'role:delete']
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title", "onBack"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseTextInput, {
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
            label: _ctx.$t('platform.common.name'),
            error: _ctx.errors.name ? _ctx.errors.name[0] : null
          }, null, 8, ["modelValue", "label", "error"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('platform.role.permissions')), 1),
          _withDirectives(_createVNode(_component_BaseSwitch, {
            "model-value": _ctx.switchValue,
            label: _ctx.$t('platform.role.all-permissions'),
            "label-position": "left",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkAll($event)))
          }, null, 8, ["model-value", "label"]), [
            [_directive_allow, 'role:assign-permission']
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions, (group, groupIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: groupIndex,
              class: "mb-4"
            }, [
              _createVNode(_component_PermissionGroup, {
                "model-value": group,
                readonly: !_ctx.canAssignPermission,
                category: groupIndex,
                "onUpdate:modelValue": ($event: any) => (_ctx.onPermissionsChange(groupIndex, $event))
              }, null, 8, ["model-value", "readonly", "category", "onUpdate:modelValue"])
            ]))
          }), 128))
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", null, [
          _createVNode(_component_BaseButton, {
            size: "large",
            loading: _ctx.saving,
            onClick: _ctx.saveRole
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"]),
          _createVNode(_component_BaseButton, {
            size: "large",
            color: "ghost",
            class: "ml-4",
            disabled: _ctx.saving,
            onClick: _ctx.goBack
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])), [
          [_directive_allow, 'role:update']
        ])
      ]),
      _: 1
    })
  ]))
}